import Model from './Model'
// import Add from './addLogic'

export default class LogicModel extends Model {
  resource () {
    return 'logics'
  }
  // add () {
  //   return this.hasMany(Add)
  // }
}
