<template>
  <div :style="computedStyle" v-show="!isHidden" :class="CSSClasses">
    <el-row style="display: flex">
      <el-col :span="24">
        <span class="registry_fields_label">{{ label }}</span>
        <div readonly class="row_formulas__edit rowFormulaIntarfaceEditor" ref="rowFomulaInObject" contenteditable="false" placeholder="Формула"></div>
        <el-button style="margin-top: 10px" @click="openEditFormula" type="primary" size="small">{{$locale.logic_editor.row_formulas.edit_formula}}</el-button>
      </el-col>
    </el-row>

    <el-dialog
      :visible.sync="dialogVisible"
      width="45%"
      :modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
<!--      <row-formulas @pasteText='pasteText' :key="1" :propslocal="propslocal" :isNew='isNew' :aliasFieldId="aliasFieldId" :targetAliasValue="targetAliasValue"/>-->
    </el-dialog>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import Entity from '@/components/ObjectEditor/Models/Entity'
import Logic from '@/components/Models/LogicModel'
import RowFormulaModel from '@/components/Models/RowFormulaModel'

export default {
  mixins: [mixin, registryMixin],
  name: 'row_formula_field',
  inject: {
    openRegistryCard: {
      default: () => () => null
    },
    getRegistryId: {
      default: () => () => null
    }
  },
  props: {
    label: {
      description: 'Название',
      type: String
    },
    state: {
      type: Number
    }
  },
  data () {
    return {
      dialogVisible: false,
      aliasFieldId: null,
      targetAliasValue: null,
      isNew: true,
      propslocal: {
        // новое действие
        isNewAction: true,
        // выбранная бизнес логика
        businesslogic: {},
        // список для построения subAction
        listOpenAction: null,
        // выбранное subAction
        selectSubAction: '',
        // значение полей от сервера
        getAnswer: ''
      }
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }

      return css
    }
  },
  methods: {
    pasteText (data) {
      // console.log(data)
      this.dialogVisible = false
      this.$refs.rowFomulaInObject.innerHTML = data.value
      this.$emit('input', data.id)
    },
    openEditFormula () {
      this.dialogVisible = true
      if (this.value) {
        this.isNew = false
      }
    }
  },
  async mounted () {
    if (this.isEditor()) {
      return false
    }
    if (this.value) {
      this.propslocal.getAnswer = await RowFormulaModel.find(JSON.parse(this.value).id)
      let logicData = await new Logic().custom('/logics').params({ registry_id: this.propslocal.getAnswer.object_id }).get()
      this.propslocal.businesslogic = logicData[0]
      this.$refs.rowFomulaInObject.innerHTML = JSON.parse(this.value).value
    } else {
      // новая формула из реестра
      // console.log('новая формула из реестра')
      let registryId = this.getRegistryId()
      if (registryId) {
        // список строковых формул реестра
        let listRowsRegistry = await new Entity().params({ object_id: registryId, type: 'row_formula_field' }).get()
        // получить properties строковой формулы
        let listProperties = await Entity.$find(listRowsRegistry.data[0].id)
        // получить id на что ссылается строковая формула
        this.aliasFieldId = listProperties.properties.find(el => el.id === 'alias_field_id').value
        // получить object_id ссылки строковой фомулы
        let parentId = await Entity.$find(this.aliasFieldId)
        // console.log(parentId.object_id)
        // console.log(registryId)
        let logicData = await new Logic().custom('/logics').params({ registry_id: parentId.object_id }).get()
        if (logicData[0]) {
          this.propslocal.businesslogic = logicData[0]
          if (registryId === parentId.object_id) {
            this.targetAliasValue = this.getModel()[`attr_${this.aliasFieldId}_`]
            // console.log('заполнить название псевдоним строку в формуле', this.targetAliasValue)
          }
        } else {
          console.warn(`реестр в бизнес-логики не найден ID: ${parentId.object_id}`)
          this.$message({
            showClose: true,
            duration: 0,
            message: `Для формулы необходимо добавить реест object_${parentId.object_id}_ в бизнес-логику!`,
            type: 'error'
          })
          return false
        }
      }
    }
  }
}
</script>

<style scoped>
.rowFormulaIntarfaceEditor {
  min-height: 85px
}
</style>
